import { useEffect } from "react";
import background from "../assets/claim_bg.png";
import ReactPixel from "react-facebook-pixel";
import tiktokPixelHandler from "react-pixel-tiktok";
import ReactPinterestTag from "react-pinterest-tag";

export const TIKTOK_PIXEL_ID = "123456789";
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

function Home() {
  useEffect(() => {
    ReactPixel.init("284977078876990", advancedMatching, options);
    ReactPixel.pageView();
    tiktokPixelHandler.init("CGVR0HJC77U442B6RQE0");
    ReactPinterestTag.init("2614179705144");
    ReactPinterestTag.pageView();
    window.location.href =
      "https://apps.apple.com/app/claim-affirmations/id1618418863";
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
      }}
      className="font-face-gm"
    >
      <h1>claim affirmations</h1>
    </div>
  );
}

export default Home;
