import background from "../assets/claim_bg.png";

function Support() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
      }}
      className="font-face-gm"
    >
      <p>In need of support?</p>
      <p>Email us at support@claimaffirmations.com</p>
    </div>
  );
}

export default Support;
