import background from "../assets/claim_bg.png";

function Privacy() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          textAlign: "left",
          paddingLeft: "25%",
          paddingRight: "25%",
          paddingTop: "10%",
          paddingBottom: "10%",
        }}
        className="font-face-gm"
      >
        <p>PRIVACY POLICY</p>
        <p>Effective date: May 12 2022</p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                By using this application, or any products, services, sites,
                features or functionality associated therewith (collectively,
                the “Application”), you agree to be bound by this Nibble Audio, Inc ("Claim") Mobile Privacy Policy (“Mobile Privacy Policy”) in addition
                to such other Claim privacy policies and terms of use. Certain features of the
                Application may require you to be a registered member or provide
                personal information.
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                This Mobile Privacy Policy describes how the Application
                collects, uses, shares and manages your information.
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  I.
                  <span data-v-d5aa26b2></span>
                  Data Collection and Use.
                </span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2></span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We may collect and store some or all of the following
                information you make available to us:
              </span>
            </span>
          </span>
        </p>
        <ul type="disc" data-v-d5aa26b2>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  Information you provide when you contact us for help
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                In addition, the Application may collect and store additional
                information automatically, such as:
              </span>
            </span>
          </span>
        </p>
        <ul type="disc" data-v-d5aa26b2>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  Device registration data (e.g., the type of mobile device you
                  use, your mobile device’s unique device or advertising ID, IP
                  address, operating system and browser type)
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  Device settings (e.g., language preference)
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>Mobile carrier</span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  Information about how you use the Application (for example,
                  how many times you use the Application each day)
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>Requested and referring URLs</span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  Location data collected through your device (including, for
                  example, precise location data such as GPS and WiFi
                  information)
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We and our service providers may use this information to operate
                and improve the Application, our other sites, applications,
                products and services, or to contact you from time to time to
                provide you with important information, notices and marketing
                promotions. The Application may allow third parties to collect
                any of the information described above in order to provide parts
                of our services as well as to provide analytical data about your
                use of the Application back to us.
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>Advertising.</span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                The Application is supported via advertising, and collects data
                to help the Application serve and display ads. We may work with
                analytics companies to help us understand how the Application is
                being used, such as the frequency and duration of usage. We work
                with advertisers and third party advertising networks, who need
                to know how you interact with the Application and your device,
                which helps us keep the cost of the Application low or free.
                Advertisers and advertising networks may access and use the
                information made available by you or collected by the
                Application, including the unique device or advertising
                identifier associated with your device and your precise location
                information, in order to help analyze and serve targeted
                advertising on the Application and elsewhere (including
                third-party sites and applications). We encourage you to review
                your device and Application settings to ensure they are
                consistent with your preferences, including with respect to the
                collection and use of such information. We may also share
                encrypted versions of information we have collected in order to
                enable our partners to append other available information about
                you for analysis or advertising-related use (on or off the
                Application, including third-party sites and applications). You
                may be able to stop further collection of certain information by
                the Application by updating your applicable device or
                Application settings, or you may uninstall the Application.
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>Third party and social services.</span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                The Application may utilize social networking or “share
                functionality” or may contain links to third party sites that
                are not owned or controlled by Claim. Claim
                has no control over, and assumes no responsibility for, any
                share functionality or the content, privacy policies, or
                practices of any third-party site. You are subject to the
                policies of those third parties when and where applicable. By
                using the Application, you expressly relieve Claim
                from any and all liability arising from your use of any share
                functionality or third-party site accessed from the Application.
                We also may allow you to use Facebook Login to leverage your
                existing Facebook account to access features of the Application.
                Please review your privacy settings on your Facebook account, as
                those settings determine what information may be made available
                to us when you access the Application.
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>Children. </span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We do not use the Application to knowingly solicit data from or
                market to children under the age of 13. If a parent or guardian
                becomes aware that his or her child has provided us with
                information without their consent, he or she should contact us
                at{" "}
              </span>
              support@claimaffirmations.com
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2></span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  II.
                  <span data-v-d5aa26b2></span>
                  Sharing Information.
                </span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We may share the information you provide or that we collect:
              </span>
            </span>
          </span>
        </p>
        <ul type="disc" data-v-d5aa26b2>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  with advertisers, third-party advertising networks and
                  analytics companies as described above;
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  to improve your experience with our Applications, products and
                  services;
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  when we believe in good faith that disclosure is necessary to
                  protect our rights, protect your safety or the safety of
                  others, investigate fraud or other security issues, or respond
                  to a government request, a subpoena or similar legal process;
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  with our service providers who work on our behalf, and do not
                  have an independent right to use the information we disclose
                  to them; for example, they may handle credit card processing,
                  data management, feature administration, email distribution,
                  market research, information analysis, and promotions
                  management; and
                </span>
              </span>
            </span>
          </li>
          <li data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  if we or the Application is involved in a merger, acquisition,
                  or sale of all or a portion of applicable stock or assets.
                </span>
              </span>
            </span>
          </li>
        </ul>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  III.
                  <span data-v-d5aa26b2></span>
                  Managing Your Information.
                </span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We retain information for as long as required, allowed or we
                believe it useful, but do not undertake retention obligations.
                We may dispose of information in our discretion without notice,
                subject to applicable law that specifically requires the
                handling or retention of information. You must keep your own
                separate back-up records. Please note that some or all of the
                data you provided may be required in order for the Application
                to function properly or may be retained in backup storage. If
                you reside outside the U.S., your information will be
                transferred to the U.S., and processed and stored there under
                U.S. privacy standards. By using the Application, you consent to
                such transfer to, and processing in, the U.S. We are concerned
                about safeguarding the confidentiality of your information. We
                provide industry-standard physical, electronic, and procedural
                safeguards to protect information we process and maintain. For
                example, we take reasonable measures to limit access to this
                information to authorized employees and contractors who need to
                know that information in order to operate, develop or improve
                our Application. Please be aware that, although we endeavor to
                provide reasonable security for information we process and
                maintain, no security system can prevent all potential security
                breaches.
                <b data-v-d5aa26b2></b>
              </span>
            </span>
          </span>
        </p>
        <p data-v-d5aa26b2>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <b data-v-d5aa26b2>
                <span data-v-d5aa26b2>
                  IV.
                  <span data-v-d5aa26b2></span>
                  Updates and Contact.
                </span>
              </b>
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                This Mobile Privacy Policy may be updated from time to time for
                any reason. We will notify you of any changes to this Mobile
                Privacy Policy by posting the new version in or via the
                Application. Please consult this Mobile Privacy Policy regularly
                for any changes. For questions about this Mobile Privacy Policy,
                please contact us at{" "}
              </span>
              support@claimaffirmations.com
            </span>
          </span>
          <span data-v-d5aa26b2>
            <span data-v-d5aa26b2>
              <span data-v-d5aa26b2>
                We will do our best to respond to you in a timely and
                professional manner to answer your questions and resolve your
                concerns. In the unfortunate and unlikely event that we cannot
                resolve your concerns, please contact the BBBOnline at
                http://www.bbbonline.org/consumer/complaint.asp and follow their
                guidelines for initiating their dispute resolution procedures.
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
